import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Drozd from './RijetkePng/Drozd.webp';
import GlavovcicTortoneseov from './RijetkePng/Glavovcic Tortoneseov.webp';
import Jegulja from './RijetkePng/Jegulja.webp';
import JesetraTuponska from './RijetkePng/JesetraTuponska.webp';
import Moruna from './RijetkePng/Modruna.webp';
import MorskiKonjic from './RijetkePng/MorskiKonjic.webp';
import NevjestaPisanica from './RijetkePng/NevjestaPisanica.webp';
import Obrvan from './RijetkePng/Obrvan.webp';
import Pojas from './RijetkePng/Pojas.webp';
import Sljuka from './RijetkePng/Sljuka.webp';
import StruljunJesetra from './RijetkePng/StrljunJesetra.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  
  {
    image: Drozd,
    name: "Drozd, Drozak",
  },
  {
    image: GlavovcicTortoneseov,
    name: "Glavočić Tortoneseov",
  },

  {  
    image: Jegulja,
    name: "Jegulja",
  },
  {  
    image: JesetraTuponska,
    name: "Jesetra, Tuponska",
  },
  {
    image: Moruna,
    name: "Moruna",
  },
  {
    image: MorskiKonjic,
    name: "Morski konjić",
  },
  {
    image: NevjestaPisanica,
    name: "Nevjesta, Pisanica",
  },
  {  
    image: Obrvan,
    name: "Obrvan",
  },
  {  
    image: Pojas,
    name: "Pojas",
  },
  {  
    image: Sljuka,
    name: "Šljuka",
  },

  {  
    image: StruljunJesetra,
    name: "Štruljun, Jesetra",
  },
];

function Rijetka() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white" >Rijetke vrste</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri rijetkih vrsta</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 163-452</p>

      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"11069083969"}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}
export default Rijetka;