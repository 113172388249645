import React, { useContext, useEffect, useRef, useState } from 'react';
import dragDropImage from './drag-drop.png';
import { saveAs} from 'file-saver';
import { PCloudAPI } from '../../../Api/PCloudAPI';
import { GetServiceId, GetServiceName, ServiceInfo, ServiceNames, UserContext } from '../../../Utilis';
import { ReactComponent as ConfirmIcon } from "../../../Png/check-solid.svg"
import Camera from '../Camera/Camera';
const DragAndDrop = ({ appName }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploaded,setUploaded]=useState(false)
    const [showConfUploBtn,setShowConfUploBtn]=useState(true)
    const [progressByFile,setFileProgressByFile]=useState({})
    const [fileTypeError, setFileTypeError] = useState('');
    const [dragOver, setDragOver] = useState(false);
    const {user,setUser} = useContext(UserContext);
    const controller = useRef(null);
    const [cameraVideoFile,setCameraVideoFile]=useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [showMailParagraph, setShowMailParagraph] = useState(false);

    const handleUpload=async ()=>{
        let _accessToken=""
        setUploadProgress(0);
        setUploaded(false)
        controller.current=new AbortController()
        //login
        try{
            const resLogin=await PCloudAPI.login()
            if (resLogin.data.result == 0) {
              _accessToken = resLogin.data.auth;
            }
        }catch(ex){
            console.log(ex)
            return
        }
        //za uzeti iz user-a tocan service folderId
        const service=GetServiceId(appName)
        const serviceNumber=ServiceNames.findIndex((element)=>element==appName)
        
        if(!service||serviceNumber<0) return;
        uploadedFiles.forEach((file,index)=>{
            let data={}
            PCloudAPI.createFolder(_accessToken,user[service],file.name)    
            .then((resFolder)=>{
            if(resFolder&&resFolder.data.result==0){
                const folderNumber=resFolder.data["metadata"]["folderid"]
                data={...data,folderName:file.name,folderId:folderNumber,serviceNumber:serviceNumber,karmenId:user.Id}
                return PCloudAPI.createService(data)
            }
            })//begin Create Service
            .then((resService)=>{
                if(resService){
                    data={...data,...resService.data}
                    setShowConfUploBtn(false)
                    return PCloudAPI.upload_file(_accessToken,resService.data.folderId,file,setFileProgressByFile,index,controller.current.signal)
                }})
            .then((resUpload)=>{
                if(resUpload){
                    data={...data,videoId:resUpload.data["metadata"][0]["fileid"],videoName:resUpload.data["metadata"][0]["name"]}
                    return PCloudAPI.createVideoUpload(data)
                }})
            .then((resVideoUpload)=>{
                setUploaded(true)
                setUploadProgress(0)
                setShowConfUploBtn(true)
                setUploadedFiles([])
            })
        .catch((ex)=>{
            console.log(ex)
        })
    })
    }

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(true);
        
        const files = event.dataTransfer.files;
        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime','video/webm'];
        const newFiles = [];
        if(user)
        for (let i = 0; i < files.length; i++) {
            if (acceptedTypes.includes(files[i].type)&&!uploadedFiles.find((element)=>element.name==files[i].name)) {
                newFiles.push(files[i]);
            } else {
                setFileTypeError(`${files[i].name} is not a supported file type.`);
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    };
    const handleFileSelect=(event)=>{
        event.preventDefault();
        const files = event.target.files
        const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime','video/webm'];
        const newFiles = [];
        console.log(files)
        if(user)
        for (let i = 0; i < files.length; i++) {
            if (acceptedTypes.includes(files[i].type)&&!uploadedFiles.find((element)=>element.name==files[i].name)) {
                newFiles.push(files[i]);
            } else {
                console.log("sad")
                setFileTypeError(`${files[i].name} is not a supported file type.`);
            }
        }
        setUploadedFiles([...uploadedFiles, ...newFiles]);
    }
    const handleButtonClick = () => {
        document.getElementById('fileInput').click();
      };
    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
        event.dataTransfer.dropEffect = 'copy';
    };
    const handleClearUploadedFiles = () => {
        setUploadedFiles([]);
        setFileTypeError('');
        setUploaded(false)
        setShowConfUploBtn(true)
        setUploadProgress(0)
        setFileProgressByFile({})
        if(controller.current)
            if (!controller.current.signal.aborted) {
                controller.current.abort();
            }
    };
    const handleConfirmUpload = (file) => {
        
        handleUpload();
    };
    const download_instr=(filename)=>{
        setShowMailParagraph(true);
        const link = document.createElement('a');
        link.href = require("./../../../Png/"+filename);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const handleDownloadInstructions = () => {
        try {
            
            switch(appName){
                case ServiceInfo.Biofouling.Name:
                    download_instr(ServiceInfo.Biofouling.instructionsPath)
                    break
                case ServiceInfo.TableApp.Name:
                    download_instr(ServiceInfo.TableApp.instructionsPath)
                    break
                case ServiceInfo.Custom.Name:
                    download_instr(ServiceInfo.Custom.instructionsPath)
                    break
                case ServiceInfo.SalmorApp.Name:
                    download_instr(ServiceInfo.SalmorApp.instructionsPath)
                    break
                case ServiceInfo.BiomassApp.Name:
                    download_instr(ServiceInfo.BiomassApp.instructionsPath)
                    break
                case ServiceInfo.Mortality.Name:
                    download_instr(ServiceInfo.Mortality.instructionsPath)
                    break
                case ServiceInfo.Waste.Name:
                    download_instr(ServiceInfo.Waste.instructionsPath)
                    break
                case ServiceInfo.Inspection.Name:
                    download_instr(ServiceInfo.Inspection.instructionsPath)
                    break
                case ServiceInfo.Cagedetection.Name:
                    download_instr(ServiceInfo.Cagedetection.instructionsPath)
                    break
                case ServiceInfo.FishCounting.Name:
                    download_instr(ServiceInfo.FishCounting.instructionsPath)
                    break
                default:
                    download_instr(ServiceInfo.TableApp.instructionsPath)
                    break
            }
        } catch (error) {
            console.error('Error generating Word document:', error);
        }
    };
    const handleFileRemove=(index)=>{
        const newFiles=uploadedFiles.filter((files,i)=>i!==index)
        setUploadedFiles([...newFiles])
    }
    useEffect(()=>{
        let count=0;
        let totalPersernt=0;
        Object.entries(progressByFile).forEach((entry)=>{
            totalPersernt+=entry[1]
            count+=1
        })
        const progress=Number(totalPersernt/count)
        setUploadProgress(progress)
        if(progress>=100){
            setUploaded(true)
            setUploadProgress(0)
            setShowConfUploBtn(true)
            setUploadedFiles([])
        }
    },[progressByFile])
    useEffect(()=>{
        if(cameraVideoFile)
            setUploadedFiles([...uploadedFiles,cameraVideoFile])
    },[cameraVideoFile])

    return (
        <div className={`container ${dragOver ? 'drag-over' : ''}`}>
            <h1>{ServiceInfo[appName].displayName}</h1>
            <button className="download-button" onClick={handleDownloadInstructions}>Download Instructions</button>
            {showMailParagraph &&appName==ServiceInfo.Biofouling.Name?(
                <b><p>Send us an e-mail (contact@karmenstudio.ai) with data requested on instruction document above</p></b>
            ):null}
            <div className="drag-drop-container" onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="drag-drop-area">
                    <img src={dragDropImage} alt="Drag and drop" className="drag-drop-image" />
                    <p><b>Drag and drop files here</b></p>
                    <p className="drag-drop-text"><b>or</b></p>
                    <div>
                        <button className='fileselect-btn' onClick={handleButtonClick}>Select files</button>
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            accept="video/mp4, video/avi, video/quicktime, video/webm"
                            multiple
                            onChange={handleFileSelect}
                        />
                    </div>
                </div>
            </div>
            {uploadedFiles.length>0 ?<>
            <div style={{ margin:"5px",backgroundColor:"#DAC625", padding:"10px",borderRadius:"10px"}} className="uploaded-files">
                <p style={{fontSize:"20px"}}>Selected files</p>
                 {uploadedFiles.map((file, index) => (
                    <div key={index} style={{display:"flex",flexDirection:"row",alignItems:"center"}}><p style={{fontWeight:"700",marginRight:"5px",cursor:"default"}}>Name: </p><p style={{fontSize:"16px",marginRight:"5px",cursor:"default"}}> {file.name}</p>{progressByFile.hasOwnProperty(index)?<span style={{fontWeight:"700"}}><img style={{paddingLeft:"5px"}} width={"15px"} src={require("../../../Png/upload.webp")}/> {progressByFile[index]} %</span>:<img style={{cursor:"pointer"}} src={require("../../../Png/delete.webp")} height={"16px"} onClick={()=>handleFileRemove(index)}/>}</div>
                ))}
            </div> 
            {showConfUploBtn?<button className='confirm' onClick={handleUpload}>Upload selected files</button>:null}
            {/* <button className='clear' onClick={handleClearUploadedFiles}>Clear selected files</button> */}
            </>:null}
                <div className="upload-progress-container">
                {uploadProgress > 0||!showConfUploBtn ?<> <progress value={uploadProgress} max="100"></progress>
                    <span>{uploadProgress.toFixed(2)}%</span></>:null}
                    {uploaded?<><p className="upload-done-info">Upload completed<ConfirmIcon className="icon" /></p>

                    {ServiceInfo[appName].hasOwnProperty("after_download_info")&&ServiceInfo[appName].after_download_info.map((s,index)=>
                        {return <p key={index} style={{color:"#354a4b"}}>{s}</p>}
                )}
                </>:null}
                </div>            
            <div>
                {fileTypeError&&false && <p className="error">{fileTypeError}</p>}
            </div>
            {/* {appName===ServiceInfo.TableApp.Name?<Camera setVideoFile={(value)=>setCameraVideoFile(value)} />:null} */}
        </div>
    );
};
export default DragAndDrop;
