import React, { useEffect, useRef, useState } from 'react';
import { ReportAPI } from '../../../Api/ReportAPI';
import { ServiceInfo, ServiceNames } from '../../../Utilis';
import VideoItem from './VideoItem';
import {Link} from "react-router-dom"
import { API_URL } from '../../../Api/PCloudAPI';
import axios from 'axios';
import { ReactComponent as DownloadIcon } from "../../../Png/download-solid.svg"

const ReportItem = ({ report, setShowedReport, showReportId }) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const [reportFilePdf,setReportFilePdf]=useState(null)
  const [videoReportProgress,setVideoReportProgress]=useState(0)
  const isDownloading=useRef(false)
  const handleDownload = () => {
    if (!videoUrl) {
      alert('No video URL available for downloading');
      return;
    }

    const link = document.createElement('a');
    link.href = videoUrl;
    link.setAttribute('download', report.videoName || 'download');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleViewDetails = () => {
    setShowedReport(); // Set the current report as opened
  
    // Check if the serviceNumber is between 0 and 9
    if (report["serviceNumber"] >= 0 && report["serviceNumber"] <= 9) {
      ReportAPI.getHlsVideo(report.Id)
        .then((res) => {
          if (res.data) {
            setVideoUrl("https://" + res.data.link);
            setShowVideo(true);
          }
        })
        .catch((error) => {
          console.error('Failed to load video:', error);
          // No alert here
          // Optionally, you can still handle the error gracefully
        });
    }
  };
  useEffect(()=>{
    if(showReportId==report.Id){
      setVideoUrl("")
      setShowVideo(false)
      setReportFilePdf(null)
    }
  },[showReportId])

  const handleDownloadVideoReport = () => {
    
    if(isDownloading.current) return
    isDownloading.current=true;
    // Construct the URL for the video file
    const downloadL = `${API_URL}/getDownloadRVideoLink/${report.Id}`;
    axios.get(downloadL, { responseType: 'blob',onDownloadProgress:(event)=>{setVideoReportProgress(event.progress*100)}}) // Convert the streamed response to a Blob
    .then(response => {
        const blob= response.data
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // Suggest a filename for the download
        a.download = report.videoReport; // You might want to dynamically determine the file name based on the response or another method
        // Append the anchor to the body, click it, and then remove it
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }).finally(()=>{
      isDownloading.current=false;
    })
    .catch(err => console.error('Error downloading file:', err));
   
  };
  function fetchReport(fileType) {
    let url=""
    let filename=""
    switch(fileType){
      case 0:
        if(report.pdfReport.split(".").pop()!="pdf")
          fileType=1
        url=`${API_URL}/getReportFiles/${report.pdfReportId}/${fileType}`
        filename=report.pdfReport
        break;
      case 1:
        url=`${API_URL}/getReportFiles/${report.pdfReportId}/${fileType}`
        filename=report.pdfReport
        break;
      case 2:
        url=`${API_URL}/getReportFiles/${report.excelReportId}/${fileType}`
        filename=report.excelReport
        break;
        default:
          return

    }
    axios({
      url: url, // Your backend endpoint
      method: 'GET',
      responseType: 'blob', // Important to handle binary data
    })
    .then((response) => {
      switch(fileType){
        case 0:
          handlePdf(response.data,filename)
          break
        case 1:
          handleFileDownload(response.data,filename)
          break;
        case 2:
          handleFileDownload(response.data,filename)
          break;

      }
    
    })
    .catch((error) => {
      console.error("Error fetching PDF:", error);
    });
  }
  function handleFileDownload(blob,filename){
    const fileUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.setAttribute('download', filename); // Optional: Specify a default filename
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(fileUrl); // Clean up

  }
  function handlePdf(blob){
    const file = new Blob([blob], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    setReportFilePdf(fileURL);
  }
  return (
    <div className="report-item">
      <h3>{report.folderName}</h3>
      <p className='service'>Service: {ServiceInfo[ServiceNames[report.serviceNumber]].displayName}</p>
      {showVideo&&showReportId==report["Id"]?<>
      <div className="pdfFileAndDownload">
        <h4>Video report:</h4>
      <a onClick={handleDownloadVideoReport}><DownloadIcon className="icon" />{report.videoReport}</a>
      {videoReportProgress > 0 ?<>
        <div>
          <p>Download progress:</p> 
      <progress value={videoReportProgress} max="100"></progress>
                    <span>{videoReportProgress.toFixed(1)}%</span></div></>:null}
      </div>
      <VideoItem videoUrl={videoUrl} showVideo={showVideo} />
      </>
      :null}

      {report.pdfReport&&showReportId==report["Id"]?

      <div onClick={()=>{fetchReport(0)}} className='pdfReport'>
        <h4>Report:</h4>
        <p className='reportName'>{report.pdfReport}</p>
        {reportFilePdf?
          <div className="pdfFileAndDownload">
          <a href={reportFilePdf} download={report.pdfReport}><DownloadIcon className="icon" />{report.pdfReport}</a>
            <iframe title={report.pdfReport} src={reportFilePdf} width="100%" height="500px" />
          </div>:null}
      </div>
      :null}
      
      <div className="button-container">
        {report["serviceNumber"]===0&&showReportId !== report.Id&&report["videoReportId"]||
        report["serviceNumber"]===1&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===2&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===3&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===4&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===5&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===6&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===7&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===8&&showReportId !== report.Id&&report["pdfReportId"]||
        report["serviceNumber"]===9&&showReportId !== report.Id&&report["pdfReportId"]?
          <button onClick={handleViewDetails}>View Details</button>
        :null}
       {/* <button onClick={handleDownload}>Download</button>  */}
      </div> 
    </div>
  );
};

export default ReportItem;
