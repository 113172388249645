import React, { useState, useEffect } from "react";
import ExportItem from './ExportItem'; 
import './../../../style.css';
import Header from './../Header';
import Footer from './../Footer';
import Hls from 'hls.js';

const ExportedVideo = ({ videos }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [videosPerPage] = useState(10);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (criteria) => {
        if (sortBy === criteria) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(criteria);
            setSortOrder('asc');
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        const videoRefs = document.querySelectorAll('video');

        videoRefs.forEach((videoRef) => {
            const hls = new Hls();
            if (Hls.isSupported()) {
                hls.loadSource(videoRef.src);
                hls.attachMedia(videoRef);
            }
        });

        return () => {
            videoRefs.forEach((videoRef) => {
                if (videoRef) {
                    videoRef.pause();
                    videoRef.removeAttribute('src');
                    videoRef.onloadstart();
                }
            });
        };
    }, [videos]);

    const handleDownload = (video) => {
        console.log('Downloading video:', video.title);
    };

    const filteredVideos = videos ? videos.filter(video =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const sortedVideos = [...filteredVideos].sort((a, b) => {
        if (sortBy === 'title') {
            return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        } else if (sortBy === 'date') {
            return sortOrder === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
        }
        return 0;
    });

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = sortedVideos.slice(indexOfFirstVideo, indexOfLastVideo);
    const totalPages = Math.ceil(sortedVideos.length / videosPerPage);

    return (
        <>
            <Header />
            <div className='app-content'>
                <div className="report-list-container">
                    <div>
                        <input
                            type="text"
                            placeholder="Search videos..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                        />

                        <div className="sort-buttons">
                            <button onClick={() => handleSortChange('title')}>Sort by Title</button>
                            <button onClick={() => handleSortChange('date')}>Sort by Date</button>
                        </div>

                        {currentVideos.map(video => (
                            <div key={video.id} className="video-item">
                                <ExportItem video={video} />
                                <div className="video-details">
                                    <p>Duration: {video.duration}</p>
                                    <p>Resolution: {video.resolution}</p>
                                    <p>Uploader: {video.uploader}</p>
                                    <p>Upload Date: {video.uploadDate}</p>
                                    <button onClick={() => handleDownload(video)}>Download</button>
                                </div>
                            </div>
                        ))}

                        <div className="pagination">
                            {Array.from({ length: totalPages }).map((_, index) => (
                                <button key={index} onClick={() => paginate(index + 1)}>{index + 1}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ExportedVideo;