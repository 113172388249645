import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

const VideoItem = ({ video }) => {
    const [videoUrl, setVideoUrl] = useState();
    const [showVideo, setShowVideo] = useState(false);
    const videoRef = useRef(null);

    const handleDownload = () => {
        if (!videoUrl) {
            console.log('No video URL available for downloading');
            return;
        }

        console.log('Downloading video:', video.title);

        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = video.title || 'download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        // This part is assumed to replace the ReportAPI call
        // For demonstration, we directly use the provided video URL
        if (video.videoUrl) {
            setVideoUrl(video.videoUrl);
            setShowVideo(true);
        }
    }, [video.videoUrl]);

    useEffect(() => {
        let hls;
        if (!videoUrl) return;
        if (Hls.isSupported()) {
            hls = new Hls();
            hls.loadSource(videoUrl);
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, function() {
                videoRef.current.play();
            });
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = videoUrl;
            videoRef.current.addEventListener('loadedmetadata', function() {
                videoRef.current.play();
            });
        }

        return () => {
            if (hls) {
                hls.destroy();
            }
        };
    }, [videoUrl]);

    return (
        <div className="video-item">
            <img src={video.thumbnailUrl} alt={video.title} />
            <div className="video-details">
                <h2>{video.title}</h2>
                <p>{video.duration}</p>
                {showVideo && (
                    <div>
                        <video className='video' ref={videoRef} controls={true} />
                    </div>
                )}
            </div>
            <div className="button-container">
                <button onClick={handleDownload}>Download</button>
            </div>
        </div>
    );
};

export default VideoItem;