import React from 'react';

const ExportItem = ({ video }) => {
    return (
        <div className="video-item">
            <img src={video.thumbnailUrl} alt={video.title} />
            <div className="video-details">
                <h2>{video.title}</h2>
                <p>{video.duration}</p>
            </div>
        </div>
    );
};

export default ExportItem;