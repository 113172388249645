import React, { useState, useRef, useEffect } from 'react';
import './ribe.css';
import { PCloudAPI } from '../../../../../Api/PCloudAPI';
import { ReactComponent as ConfirmIcon } from './../../../../../Png/check-solid.svg'

const Datoteka = ({ existingFolderId }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const controller = useRef(null);
  const [progressByFile, setFileProgressByFile] = useState({});
  const [uploaded, setUploaded] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [clearFilesClicked, setClearFilesClicked] = useState(false);

  const fileInputRef = useRef(null);

  const handleUpload = async () => {
    let _accessToken = '';
    setUploadStarted(true);
    setUploadProgress(0);
    setUploaded(false);
    controller.current = new AbortController();
    console.log("fileovi", selectedFiles);
    try {
      const resLogin = await PCloudAPI.login();
      if (resLogin.data.result === 0) {
        _accessToken = resLogin.data.auth;
      }
    } catch (ex) {
      console.log(ex);
      return;
    }
    selectedFiles.forEach((file, index) => {
      let data = { "folderId": null };
      data.folderId = existingFolderId;

      PCloudAPI.upload_file(_accessToken, existingFolderId, file, setFileProgressByFile, index, controller.current.signal)
        .then((resVideoUpload) => {
        })
        .catch((ex) => {
          console.log(ex);
        });
    });
  };

  const handleFileSelect = (event) => {
    event.preventDefault();
    const files = event.target.files
    const acceptedTypes = ['video/mp4', 'video/avi', 'video/quicktime', 'video/webm', 'images/jpg', 'images/png'];
    const newFiles = [];
    console.log(files)
    for (let i = 0; i < files.length; i++) {
      newFiles.push(files[i]);
    }
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleClearFiles = () => {
    setSelectedFiles([]);
    setUploadProgress(0);
    setError('');
    setClearFilesClicked(true);
    if (controller.current && !controller.current.signal.aborted) {
      controller.current.abort();
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0)
      setShowSaveButton(true);
    else
      setShowSaveButton(false);
  }, [selectedFiles]);

  useEffect(() => {
    let count = 0;
    let totalPersernt = 0;
    Object.entries(progressByFile).forEach((entry) => {
      totalPersernt += entry[1];
      count += 1;
    });
    const progress = Number(totalPersernt / count);
    setUploadProgress(progress);
    if (progress >= 100) {
      setUploaded(true);
      setUploadProgress(0);
    }
  }, [progressByFile]);

  const handleSaveFiles = () => {
    if (selectedFiles.length > 0) {
      setUploading(true);
      setTimeout(() => {
        setUploading(false);
        setUploadComplete(true);
      }, 2000);
    } else {
      setError('Please select at least one file before saving.');
    }
  };

  return (
    <div className="camera-container">
      <input
        type="file"
        id="fileInput"
        accept="image/*, video/*"
        onChange={handleFileSelect}
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
      />
      <div className="button-container">
        <button onClick={() => { document.getElementById('fileInput').click(); }} className="button">
          Upload
        </button>

        {showSaveButton && (
          <>
            <button onClick={handleUpload} className="button">
              Spremi
            </button>
          </>
        )}
      </div>
      {uploadStarted && (
        <div className="upload-progress-container-fishes">
          {uploadProgress > 0 ? (
            <>
              <progress value={uploadProgress} max="100"></progress>
              <span>{uploadProgress.toFixed(2)}%</span>
            </>
          ) : null}
          {uploaded && !clearFilesClicked ? (
            <>
              <p className="upload-done-info">Spremanje završeno<ConfirmIcon className="icon" /></p>
              <p style={{ color: "green", fontSize:"18px" }}>Hvala vam na podršci projektu AI- Ribe Jadrana!</p>
              <p style={{ fontSize:"14px" }}>Sve prikupljene fotografije će se koristiti isključivo za trening softvera za automatsko prepoznavanje vrste, roda i obitelji riba iz fotografija.  I neće se upotrebljavati u komercijalno/marketinške svrhe niti objavljivati.</p>
              <p style={{ color: "green", fontSize:"18px" }}>Thank you for supporting the AI - Adriatic Fish project!</p>
              <p style={{ fontSize:"14px" }}>We are gathering images to train software that automatically recognizes fish species, genera, and families from photos. All images will be used exclusively for software training and not for commercial / marketing purposes. Photos will not be published.  </p>
        </>
          ) : null}
        </div>
      )}
      {uploading && <p>Pričekajte dok se učita...</p>}
      {selectedFiles.length > 0 && (
        <div className="selected-files">
          <p>Odabrane datoteke:</p>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
          <button onClick={handleClearFiles} className="button">
            Obriši odabir
          </button>
        </div>
      )}
      {error && <p className="error">{error}</p>}
    </div> 
  );
};

export default Datoteka;