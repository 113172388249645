import React from 'react';
import './../Apps.css';
import DragDrop from './../DragDrop';

const Waste = ({name}) => {
    return (
        <DragDrop appName={name}/>
    );
};

export default Waste;