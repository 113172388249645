import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Components/Home/home'
import ForgotPassword from './Components/Login/ForgotPassword/forgot_password'
import ResetPassword from './Components/Reset password/reset_password'
import LoginPage from './Components/Login/Login'
import ReportList from './Components/Home/Myreport/ReportList'
import OriginalVideos from './Components/Home/OriginalVideos/OriginalVideos'
import ExportedVideo from './Components/Home/ExportedVideo/ExportedVideo'
import {ServiceInfo, ServiceNames, UserContext} from "./Utilis"
import { useEffect, useState } from 'react'
import Biofouling from './Components/Home/Apps/Biofouling/Biofouling';
import TableApp from './Components/Home/Apps/TableApp/TableApp';
import FishCounting from './Components/Home/Apps/FishCounting/FishCounting';
import SalmorApp from './Components/Home/Apps/SalmorApp/SalmorApp';
import BiomassApp from './Components/Home/Apps/BiomassApp/BiomassApp';
import Mortality from './Components/Home/Apps/Mortality/Mortality';
import Waste from './Components/Home/Apps/Waste/Waste';
import Inspection from './Components/Home/Apps/Inspection/Inspection';
import Custom from './Components/Home/Apps/Custom/Custom';
import Cagedetection from './Components/Home/Apps/Cagedetection/Cagedetection';
import AppDisplay from './Components/Home/AppDisplay'
import MorskiPsi from './Components/Home/Apps/Custom/Vrste/VrsteRiba/MorskiPas'
import MorskaMacka from './Components/Home/Apps/Custom/Vrste/VrsteRiba/MorskeMacke'
import Sklat from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Sklat'
import RazoPas from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Razopas'
import Golub from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Golubovi'
import Drhtulja from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Drhtulja'
import Morskistakor from './Components/Home/Apps/Custom/Vrste/VrsteRiba/MorskiStakor'
import Raze from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Raza'
import Nepoznato from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Nepoznato'
import SveVrste from './Components/Home/Apps/Ribe/Svevrste'
import Kitovi from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Kitovi'
import Kornjace from './Components/Home/Apps/Custom/Vrste/VrsteRiba/Kornjace'
import Sredozemna from './Components/Home/Apps/Custom/Vrste/VrsteRiba/SredozemneMedvjednice'
import Komercijalne from './Components/Home/Apps/Custom/Vrste/VrsteRiba/KomercijalneVrste'
import Rijetke from './Components/Home/Apps/Custom/Vrste/VrsteRiba/RijetkeVrste'
import NotFound from './Components/404/Error'
import BiofoulingReport from './Components/Home/Apps/Mowi/BiofoulingReport'
import BiomassCageReport from './Components/Home/Apps/BiomassCageReport/BiomassCageReport'

function App() {
  const [user,setUser]=useState(null)

 
  return (
    <UserContext.Provider value={{user:user,setUser:setUser}}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage />}></Route>
          <Route path='/*' element={<NotFound />}></Route>
          <Route path = "/forgotPassword" element={<ForgotPassword />}></Route>
          <Route path = "/resetPassword/:token" element={<ResetPassword />}></Route>
          <Route path="/mowi/:path" element={<BiofoulingReport />} />
          <Route path="/ribe" element={<SveVrste/>} /> 
            <Route path = "ribe/morski_pas" element={<MorskiPsi />}></Route>
            <Route path = "/ribe/morska_macka" element={<MorskaMacka/>}></Route>
            <Route path = "/ribe/sklat" element={<Sklat/>}></Route>
            <Route path = "/ribe/razopas" element={<RazoPas/>}></Route>
            <Route path = "/ribe/golubovi" element={<Golub/>}></Route>
            <Route path = "/ribe/drhtulja" element={<Drhtulja/>}></Route>
            <Route path = "/ribe/morski_stakor" element={<Morskistakor/>}></Route>
            <Route path = "/ribe/raza" element={<Raze/>}></Route>
            <Route path="/ribe/nepoznate_ribe" element= {<Nepoznato/>}></Route>
            <Route path="/ribe/kitovi" element= {<Kitovi/>}></Route>
            <Route path="/ribe/kornjace" element= {<Kornjace/>}></Route>
            <Route path="/ribe/sredozemna_medvjednica" element= {<Sredozemna/>}></Route>
            <Route path="/ribe/komercijalne_vrste" element= {<Komercijalne/>}></Route>
          <Route path="/ribe/rijetke_vrste" element= {<Rijetke/>}></Route>
          <Route path="/home" element={<Home/>} >
            <Route path="/home/apps" element={<AppDisplay />} />
            <Route path="/home/tableapp" element={<TableApp name={ServiceInfo.TableApp.Name} service="TableApp" />} />
            <Route path="/home/fishcounting" element={<FishCounting name={ServiceInfo.FishCounting.Name} service="FishCounting" />} />
            <Route path="/home/salmorapp" element={<SalmorApp name={ServiceInfo.SalmorApp.Name} service="SalmorApp" />} />
            <Route path="/home/biomassapp" element={<BiomassApp name={ServiceInfo.BiomassApp.Name} service="BiomassApp" />} />
            <Route path="/home/mortality" element={<Mortality name={ServiceInfo.Mortality.Name} service="Mortality" />} />
            <Route path="/home/waste" element={<Waste name={ServiceInfo.Waste.Name} service="Waste" />} />
            <Route path="/home/inspection" element={<Inspection name={ServiceInfo.Inspection.Name} service="Inspection" />} />
            <Route path="/home/custom" element={<Custom name={ServiceInfo.Custom.Name} service="Custom" />} />
            <Route path="/home/biofouling" element={<Biofouling name={ServiceInfo.Biofouling.Name} />} /> 
            <Route path="/home/cagedetection" element={<Cagedetection name={ServiceInfo.Cagedetection.Name} service="Cagedetection" />} />
            <Route path="/home/MyReport/ReportList" Component={ReportList}/>
            <Route path='/home/OriginalVideos/OriginalVideos' Component={OriginalVideos}/>
            <Route path='/home/ExportedVideo/ExportedVideo' Component={ExportedVideo}/>
            <Route path='/home/biomassCageReport/:cageId' element={<BiomassCageReport/>} />

          </Route>
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  )
}

export default App;
