import { createContext } from "react";
import CryptoJS from "crypto-js"
import { UserAPI } from "../Api/UserAPI";
import axios from "axios";
export const UserContext=createContext()
export const ServiceNames=["TableApp","FishCounting","SalmorApp","BiomassApp","Waste",
"Biofouling","Cagedetection","Inspection","Mortality","Custom"]
export const ServiceInfo={
    TableApp: {Name: "TableApp", displayName: "TableApp",link_url:"/home/tableapp",instructionsPath:"Instructions_tableapp_users.pdf",after_download_info:["The analysis lasts 1.5 times the length of the video.","You will see the results in the 'My Reports' section after the blue button labeled 'View Report' appears."]},
    FishCounting: {Name: "FishCounting", displayName: "Tuna Counting",link_url:"/home/fishcounting", instructionsPath:"Instructions_tunacounting_users.pdf"},
    SalmorApp: {Name: "SalmorApp", displayName: "SALmorApp",link_url:"/home/salmorapp", instructionsPath:"Instructions_salmorapp_users.pdf"},
    BiomassApp: {Name: "BiomassApp", displayName: "FishBIOMETRY",link_url:"/home/biomassapp", instructionsPath:"Instructions_biometry_users.pdf"},
    Mortality: {Name: "Mortality", displayName: "FishMORTALITY",link_url:"/home/mortality", instructionsPath:"mortality_instructions.pdf"},
    Waste: {Name: "Waste", displayName: "FoodWASTE",link_url:"/home/waste", instructionsPath:"foodwaste_instructions.pdf"},
    Inspection: {Name: "Inspection", displayName: "100% Inspection",link_url:"/home/inspection", instructionsPath:"inspection_instructions.pdf"},
    Custom: {Name: "Custom", displayName: "Custom",link_url:"/home/custom",instructionsPath:"Instructions_custom_users.pdf"},
    Biofouling: {Name: "Biofouling", displayName: "BioFOULING",link_url:"/home/biofouling",instructionsPath:"biofouling_instructions.pdf"},
    Cagedetection: {Name: "Cagedetection", displayName: "HOLEdetection",link_url:"/home/cagedetection", instructionsPath:"detection_instructions.pdf"}
}

export const GetServiceName=(value)=>{
    switch(value){
        case "TableApp": return "tableApp";
        case "FishCounting": return "fishCounting";
        case "SalmorApp": return "salmorApp";
        case "BiomassApp": return "biomassApp";
        case "Mortality": return "fishMortality";
        case "Waste": return "foodWaste";
        case "Inspection": return "inspectionCoverage"; 
        case "Custom": return "customSolutions";
        case "Biofouling": return "biofoulingOnNets";
        case "Cagedetection": return "cageHolesDetection";
        default:
        return null
    }
    
}

export const GetServiceId=(value)=>{
    switch(value){
        case "TableApp": return "tableAppId";
        case "FishCounting": return "fishCountingId";
        case "SalmorApp": return "salmorAppId";
        case "BiomassApp": return "biomassAppId";
        case "Mortality": return "fishMortalityId";
        case "Waste": return "foodWasteId";
        case "Inspection": return "inspectionCoverageId"; 
        case "Custom": return "customSolutionsId";
        case "Biofouling": return "biofoulingOnNetsId";
        case "Cagedetection": return "cageHolesDetectionId";
        default:
        return null
    }
    
}

export function sha256(message){
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
}

export function getUserFromToken(token,setUser,navigate,navigateBadToken="/",navigateGoodToken){
    if(token)
        axios.defaults.headers.common['user_authorization'] = `Bearer ${token}`;
        UserAPI.verifyLogin().then((response)=>{
            if(response.data){
                setUser(response.data.user)
                const remMe=localStorage.getItem("rememberMe")==="true"
                
                if (remMe){
                    console.log(remMe)
                    localStorage.setItem('token',response.data.token)
                }
                else{
                    console.log(remMe)
                    sessionStorage.setItem('token',response.data.token)
                }
                if(navigateGoodToken)
                        navigate(navigateGoodToken)
            }
        }).catch((err)=>{
            console.log(err)
            setUser(null)
            navigate(navigateBadToken)
        });
    
    
}
