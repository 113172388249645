import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import Marmorata from './DrhtuljaPng/Marmorata.webp';
import Nobiliana from './DrhtuljaPng/Nobiliana.webp';
import Torpedo from './DrhtuljaPng/Torpedo.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image : Marmorata,
    name: "Drhtulja (T. marmorata)",
  },
  {
    image: Nobiliana,
    name: "Drhtulja (T. nobiliana)",
  },
  {
    image: Torpedo,
    name: "Drhtulja (T. torpedo)",
  },
];

function Drhtulja() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{  padding: "20px", borderRadius: "10px" }}>
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white">Drhtulja</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri drhtulja</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162</p>
      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"10880448599"}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}

export default Drhtulja;