import React, { useState, useEffect } from 'react';
import VideoItem from './VideoItem';
import './../../../style.css';

const OriginalVideo = ({ videos }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [videosPerPage] = useState(10);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSortChange = (criteria) => {
        if (sortBy === criteria) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(criteria);
            setSortOrder('asc');
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const filteredVideos = videos ? videos.filter(video =>
        video.title.toLowerCase().includes(searchTerm.toLowerCase())
    ) : [];

    const sortedVideos = [...filteredVideos].sort((a, b) => {
        if (sortBy === 'title') {
            return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        } else if (sortBy === 'date') {
            return sortOrder === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
        }
        return 0;
    });

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = sortedVideos.slice(indexOfFirstVideo, indexOfLastVideo);
    const totalPages = Math.ceil(sortedVideos.length / videosPerPage);

    useEffect(() => {
        // This effect could be for loading videos initially if needed.
    }, []);

    return (
        <>
            <div className='app-content'>
                <div className="report-list-container">
                    <div>
                        <input
                            type="text"
                            placeholder="Search videos..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                        />

                        <div className="sort-buttons">
                            <button onClick={() => handleSortChange('title')}>Sort by Title</button>
                            <button onClick={() => handleSortChange('date')}>Sort by Date</button>
                        </div>

                        {currentVideos.map(video => (
                            <VideoItem key={video.id} video={video} />
                        ))}

                        <div className="pagination">
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button key={index} onClick={() => paginate(index + 1)}>{index + 1}</button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OriginalVideo;