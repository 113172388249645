import React, { useRef, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import logo from './../../../../../../Png/small.webp'; 
import "./Navbar.css";

function Navbar() {
    const navRef = useRef();
    const [scrolled, setScrolled] = useState(false);
    const [navOpen, setNavOpen] = useState(false);

    const showNavbar = () => {
        setNavOpen(!navOpen);
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header className={scrolled ? "scrolled" : ""}>
            <div className="arrow-container">
                <a href="/ribe" className="arrow-link" aria-label="Go to previous page">
                    <FontAwesomeIcon icon={faArrowLeft} />
                </a>
            </div>
            <nav ref={navRef} className={navOpen ? "responsive_nav" : ""}>
                <button 
                    className={`nav-btn ${navOpen ? "nav-close-btn" : ""}`} 
                    onClick={showNavbar}
                    aria-label="Toggle navigation"
                ></button>
            </nav>
            <div className="logo-container">
                <a href="https://bluedatab.com/" className="home-link">
                    <img src={logo} alt="Logo" className="logo" />
                </a>
            </div>
        </header>
    );
}

export default Navbar;
