import axios from "axios"
import { API_URL } from "./PCloudAPI"


export const ReportAPI={

    getReports:()=>{
        return axios.get(`${API_URL}/getReports`)
    },
    getHlsVideo:(id)=>{
        return axios.get(`${API_URL}/getVideo/${id}`)
    },
    getReportFiles:(id)=>{
        return axios.get(`${API_URL}/getReportFiles/${id}`)
    },
    getDownloadRVideoLink:(id)=>{
        return axios.get(`${API_URL}/getDownloadRVideoLink/${id}`)
    }

}