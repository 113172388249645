import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arbun from './KomercijalnePng/Arbun.webp';
import Bezmek from './KomercijalnePng/Bezmek.webp';
import Brancin from './KomercijalnePng/Brancin.webp';
import CipalGlavas from './KomercijalnePng/CipalGlavas.webp';
import Fratar from './KomercijalnePng/Fratar.webp';
import Gavun from './KomercijalnePng/Gavun.webp';
import GiraAtlantska from './KomercijalnePng/GiraAtlantska.webp';
import Gof from './KomercijalnePng/Gof.webp';
import Grdobina from './KomercijalnePng/Grdobina.webp';
import Incun from './KomercijalnePng/Incun.webp';
import Komarca from './KomercijalnePng/Komarca.webp';
import Kovac from './KomercijalnePng/Kovac.webp';
import Lubin from './KomercijalnePng/Lubin.webp';
import Palamida from './KomercijalnePng/Palamida.webp';
import Patarca from './KomercijalnePng/Pataraca.webp';
import PaukBijelac from './KomercijalnePng/PaukBijelac.webp';
import Salpa from './KomercijalnePng/Salpa.webp';
import SkrpunBodec from './KomercijalnePng/SkrpunBodec.webp';
import SkusaVrnut from './KomercijalnePng/SkusaVrnut.webp';
import Spar from './KomercijalnePng/Spar.webp';
import Srdela from './KomercijalnePng/Srdela.webp';
import Strijelka from './KomercijalnePng/Strijelka.webp';
import TrljaOdKamena from './KomercijalnePng/TrljaOdKamena.webp';
import Tunj from './KomercijalnePng/Tunj.webp';
import Ugor from './KomercijalnePng/Ugor.webp';
import Zubatac from './KomercijalnePng/Zubatac.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image :Arbun,
    name: "Arbun",
  },
  
  {
    image: Bezmek,
    name: "Bezmek",
  },
  {
    image: Brancin,
    name: "Brancin",
  },

  {
    image: CipalGlavas,
    name: "Cipal Glavaš",
  },
  {  
    image: Fratar,
    name: "Fratar",
  },
  {  
    image: Gavun,
    name: "Gavun",
  },
  {  
    image: GiraAtlantska,
    name: "Gira Atlantska",
  },
  {  
    image: Gof,
    name: "Gof",
  },
  {
    image :Grdobina,
    name: "Grdobina",
  },
  {
    image: Incun,
    name: "Inčun",
  },

  {
    image: Komarca,
    name: "Komarča",
  },
  {  
    image: Kovac,
    name: "Kovač",
  },
  {  
    image: Lubin,
    name: "Lubin",
  },
  {  
    image: Palamida,
    name: "Palamida",
  },
  {  
    image: Patarca,
    name: "Patarača",
  },
  {  
    image: PaukBijelac,
    name: "Pauk Bijelac",
  },

  {  
    image: Salpa,
    name: "Salpa",
  },
  {  
    image: SkrpunBodec,
    name: "Škrpun Bodec",
  },
  {  
    image: SkusaVrnut,
    name: "Skuša Vrnut",
  },
  {  
    image: Spar,
    name: "Špar",
  },
  {
    image: Srdela,
    name: "Srdela",
  },
  {  
    image: Strijelka,
    name: "Strijelka",
  },
  {  
    image: TrljaOdKamena,
    name: "Trlja od kamena",
  },
  {  
    image: Tunj,
    name: "Tunj",
  },
  {  
    image: Ugor,
    name: "Ugor",
  },
  {  
    image: Zubatac,
    name: "Zubatac",
  },
];

function Rijetka() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white" >Komercijalne vrste</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri komercijalnih vrsta</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 163-452</p>

      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"11069082042"}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}
export default Rijetka;