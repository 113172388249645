import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Plot from 'react-plotly.js';
import './ReportApp.css';
import { API_URL } from '../../../../Api/PCloudAPI';
import axios from 'axios';
// import map_cage from "./image.png"
// import biofouling from "./noTitle_bio.png"
// import fr_scale from "./FR_scale3.png"


const BiofoulingReport = () => {
  const [imageSrc, setImageSrc] = useState('');
  const [toggleImages, setToggleImages] = useState(true);
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({});
  const imageRef = useRef(null);
  const abortControllerRef=useRef(null)
  const [folderId,setFolderId]=useState(null)
  const [cageInfo,setCageInfo]=useState(null)
  //parametri za path od reporta
  const {path}=useParams()
  useEffect(() => {
    //u url-u dobijemo path za nas report, napravit funkciju u backendu da iz tog patha iz baze podataka uzme dobar folderId
    // -> nova tablica sa url-ima i folderid-ovima 
    axios.get(`${API_URL}/getReportUrlId/${path}`).then((res)=>{
      setFolderId(res.data.reportId)
      setCageInfo(res.data)
      return axios.get(`${API_URL}/getReportData/${res.data.reportId}`)
    }).then((response)=>{
      const safeEval = new Function('extractedData', response.data + '; return {csv_data,points_info};');
      const {csv_data,points_info}=safeEval()
        
      const heatmapTrace = {
        x: csv_data.HEADING,
        y: csv_data.DEPTH,
        z: csv_data.FR,
        type: 'heatmap',
        colorscale: [
          [0.0, '#006937'],
          [0.25, '#86CB66'],
          [0.5, '#FFFFBE'],
          [0.75, "#F99455"],
          [1.0, '#A60126']
        ],
        zsmooth: 'best',
        zmin: 0,
        zmax: 10,
        colorbar: { title: 'Fouling<br>ratio' },
        showscale: true
      };
  
      const scatterTrace = {
        x: points_info.map(p => p.x),
        y: points_info.map(p => p.y),
        mode: 'markers',
        customdata: points_info,
        name: 'Point',
        hovertemplate: 'Heading: %{customdata.x}<br>Depth: %{customdata.y} <br>FR: %{customdata.z}<extra></extra>'
      };
  
      const layout = {
        title: {
          text: "Heatmap",
          y: 1.0,
          x: 0.5,
          xanchor: 'center',
          yanchor: 'top'
        },
        autosize: true,
        plot_bgcolor: 'rgba(0,0,0,0)',
        font: { size: 18 },
        xaxis: { title: "Heading", tickmode: 'array', tickvals: csv_data.HEADING, ticktext: csv_data.HEADING, showgrid: false, zeroline: false },
        yaxis: { title: "Depth [m]", range: [-16, 0], showgrid: false, zeroline: false },
        shapes: [
          { type: 'line', x0: 0, y0: 10, x1: 0, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
          { type: 'line', x0: 90, y0: 10, x1: 90, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
          { type: 'line', x0: 180, y0: 10, x1: 180, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
          { type: 'line', x0: 270, y0: 10, x1: 270, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } },
          { type: 'line', x0: 360, y0: 10, x1: 360, y1: -40, line: { color: 'rgb(0, 0, 0)', width: 1, dash: 'solid' } }
        ],
        annotations: [
          { x: 0, y: 10, xref: 'x', yref: 'y', text: 'N', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 90, y: 10, xref: 'x', yref: 'y', text: 'E', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 180, y: 10, xref: 'x', yref: 'y', text: 'S', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 270, y: 10, xref: 'x', yref: 'y', text: 'W', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' },
          { x: 360, y: 10, xref: 'x', yref: 'y', text: 'N', showarrow: false, font: { family: 'Arial', size: 18, color: 'rgb(0, 0, 0)' }, xanchor: 'center', yanchor: 'bottom' }
        ]
      };
  
      setData([heatmapTrace, scatterTrace]);
      setLayout(layout);
    })
  

    return () => {
      if (abortControllerRef.current) {
          abortControllerRef.current.abort();
      }
  };

  }, []);

  function handleHover(event){ 
    try{
    // Abort previous request if any
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
     // create a new AbortController
     const abortController = new AbortController();
     abortControllerRef.current = abortController;
    const pointIndex = event.points[0];
    if (toggleImages && pointIndex.customdata && pointIndex.customdata.img_paths) {
        axios.post(`${API_URL}/getReportImage`,{
            folderId:folderId,
            imgName:pointIndex.customdata.img_paths
        },
        {
        signal:abortController.signal,
        responseType: 'blob' }).then((response)=>{
        if(!response.data)return
        const url = URL.createObjectURL(response.data);
        setImageSrc(url);
    }).catch((ex)=>{
    })

    } else {
      setImageSrc('');
    }
}catch(ex){

}
  };

  const handleClick = (event) => {
    const pointIndex = event.points[0];
    if (toggleImages && pointIndex.customdata && pointIndex.customdata.img_paths)  {
      toggleFullScreen(imageRef.current);
    }
  };

  const toggleFullScreen = (element) => {
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE/Edge */
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };
  function showDate(date_for_formating){
    const date = new Date(date_for_formating);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
return formattedDate
  }
  return (
    <div className='biofouling-heatmap-report'>
      <div className="title-container">
        <img width="120px" src="/noTitle_bio.png" alt="Biofouling Logo" />
        <h1 className="title"><span style={{ color: '#DBC607' }}>BioFOULING</span></h1>
      </div>
      <h1 style={{ color: '#f8f8f8', fontSize: '5rem', textAlign: 'center', marginBottom: '2rem' }}>Report</h1>
      {cageInfo?<div className="reportInfo_heatmap">
        <div className="report-info">
          <div className="info">
            <p><span className="info-title">Date: </span><span className="info-content">{showDate(cageInfo.reportDate)}</span></p>
          </div>
          <div className="info">
            <p><span className="info-title">Location: </span><span className="info-content">{cageInfo.location}</span></p>
          </div>
          <div className="info">
            <p><span className="info-title">Cage: </span><span className="info-content">{cageInfo.cageName}</span></p>
          </div>
        </div>
        <div className="heatmap">
          <div id="image_container">
            <h2 id="image-info" style={{ display: imageSrc ? 'block' : 'none' }}></h2>
            <img ref={imageRef} id="image" width="100%" src={imageSrc} alt="Corresponding" style={{ display: imageSrc ? 'block' : 'none' }} />
          </div>
          <div className="showImg-toggle">
            <label> <p>Show images </p></label>
            <label className="toggle-switch">
              <input type="checkbox" id="toggle" checked={toggleImages} onChange={() => setToggleImages(!toggleImages)} />
              <span className="slider"></span>
            </label>
          </div>
          <div id="container">
            <Plot
              data={data}
              layout={layout}
              useResizeHandler
              style={{ width: "100%", height: "100%" }}
              onHover={(e)=>handleHover(e)}
              onClick={handleClick}
              config={{ displayModeBar: false }}
            />
          </div>
        </div>
      </div>:null}
      <div className="image-info">
        <img style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }} width="60%" src="/FR_scale3.png" alt="FR Scale" />
        <img style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }} width="40%" src="/image.png" alt="Image" />
      </div>
      <div style={{ marginBottom: '80px' }}>
        <h3 style={{ textAlign: 'center', color: '#f8f8f8', fontSize: '3rem' }}>Description</h3>
        <p style={{ textAlign: 'center', marginBottom: '20px', color: '#f8f8f8' }}>
          Circle cage is unfolded and looks like four connected squares, where each square represents one side of the world (North, East, South, West)
        </p>
      </div>
    </div>
  );
};

export default BiofoulingReport;
