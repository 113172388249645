import { useRef,useEffect } from "react";

import Hls from 'hls.js';

function VideoItem({videoUrl,showVideo}){
    const videoRef = useRef(null);

    useEffect(() => {
        let hls=null
        if (showVideo && videoUrl && Hls.isSupported()) {
          hls=new Hls();
          hls.loadSource(videoUrl);
          hls.attachMedia(videoRef.current);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            //videoRef.current.play();
          });
        } else if (showVideo && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = videoUrl;
          videoRef.current.addEventListener('loadedmetadata', () => {
            //videoRef.current.play();
          });
        } else if(!showVideo){
            videoRef.current.pause()
            videoRef.current.src=""
            videoRef.current.load()
        }
        
        return () => {
          if (hls) {
            hls.destroy();
          } else if (videoRef.current) {
            // For natively supported HLS, clean up here
            videoRef.current.pause();
            videoRef.current.src = '';
            videoRef.current.load(); // This is necessary to properly reset the video state
          }
        };
      }, [showVideo, videoUrl]);
      useEffect(()=>{
       
      },[showVideo])
    return(
    showVideo&&videoUrl?<div>
        <video className='video' ref={videoRef} controls />
    </div>:null
    )
}

export default VideoItem