import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import GlavataZelva from './KornjacaPng/GlavataZelva.webp';
import SedmeroprugaUspinjaca from './KornjacaPng/SedmeroprugaUsminjaca.webp';
import ZelenaZelva from './KornjacaPng/ZelenaZelva.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image : GlavataZelva,
    name: "Glavata Želva",
  },
  {
    image: SedmeroprugaUspinjaca,
    name: "Sedmeropruga Usminjača",
  },
  {
    image: ZelenaZelva,
    name: "Zelena Želva",
  },
];

function Kornjaca() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white">Kornjače</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjer kornjača</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        {/* <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162</p> */}
      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"11069080187"}/>
          </div>
        </div>
    </div>
    <Footer/>
    </>
  );
}

export default Kornjaca;
