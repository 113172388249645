import React from 'react';
import Slider from 'react-slick';
import './slider.css';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Uljestura from './KitoviPng/Uljestura.webp';
import VelikiKit from './KitoviPng/VelikiKit.webp';
import BjelogrliDupin from './KitoviPng/BjelogrliDupin.webp';
import CrniDupin from './KitoviPng/CrniDubin.webp';
import DobriDupin from './KitoviPng/DobriDupin.webp';
import KrupnozubiDupin from './KitoviPng/KrupnozubiDupin.webp';
import ObicniDupin from './KitoviPng/ObicniDupin.webp';
import PrugastiDupin from './KitoviPng/PrugastiDupin.webp';
import Ribe from './../Ribe';
import Navbar from './../Navbar/RibeNavbar';
import Footer from '../Footer/Footer';

const areas = [
  {
    image : Uljestura,
    name: "Ulješura",
  },
  {
    image: VelikiKit,
    name: "Veliki kit",
  },
  {
    image : BjelogrliDupin,
    name: "Bjelogrli dupin",
  },
  {
    image: CrniDupin,
    name: "Crni dupin",
  },
  {
    image: DobriDupin,
    name: "Dobri dupin",
  },
  {
    image : KrupnozubiDupin,
    name: "Krupnozubi dupin",
  },
  {
    image: ObicniDupin,
    name: "Obični dupin",
  },
  {
    image: PrugastiDupin,
    name: "Prugasti dupin",
  },
  
];

function Kitovi() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
    <Navbar/>
    <div className="container-fluid py-5" id="testimonial">
      <div className="new-container" style={{ padding: "20px", borderRadius: "10px" }}>
        <div className="position-relative d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-uppercase text-white">Kitovi</h1>
          <h1 className="position-absolute text-uppercase text-primary">Primjeri kitova</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12">
          <Slider {...settings}>
  {areas.map((area, index) => (
    <div key={index} className="testimonial-slide text-center">
      <div className="testimonial-content-wrapper">
        <img src={area.image} alt={area.name} className="testimonial-image" />
        <h2 className="testimonial-name">{area.name}</h2>
        {/* <p className="text-center" style={{ fontSize: "18px", marginTop: "20px" }}>Izvor grafika riba: I. Jadras, "Jadranska ihtiofauna"; 1996, str. 119-162</p> */}
      </div>
    </div>
  ))}
</Slider>
<Ribe existingFolderId={"11069078234"}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}

export default Kitovi;